require('./bootstrap');

require('alpinejs');
require('@markusantonwolf/ta-gallery/src/scripts/ta-gallery');
require('aos');
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

import lazySizes from 'lazysizes';
lazySizes.init();

import AOS from 'aos';

AOS.init();
